import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle"
import PricingCard from "../components/PricingCard"
import Seo from "../components/Seo"
import TrustedByBanner from "../components/TrustedByBanner"

const PricingPage = () => (
  <Layout>
    <Seo
      title="Pricing"
      description="Upfeed pricing plans - affordable pricing for the leading ecomm centralized product feed solution"
    />
    <Pagetitle>Pricing</Pagetitle>

    <div className={`row d-flex justify-content-center mb-3 text-center`}>
      <PricingCard price="$9.99" products="<1000" />
      <PricingCard price="$19.99" products="1k-10k" />
      <PricingCard price="$39.99" products=">10k" />
    </div>
  </Layout>
)

export default PricingPage
