import React from "react";

const PricingCard = (props) => {

  const { price, products } = props;

  return (
    <div className="col-md-3">
      <div className={`card mb-4 card-shadow`}>
        <div className="card-body">
          <div className="pricing-price">
            <h1 className={`card-title pricing-card-title`}>{price} <small className="text-muted">/ mo</small></h1>
            <h4 className={`my-0 price`}>{products} Products</h4>
          </div>

          <div className="priceline"></div>

          <ul className="list-unstyled mt-3 mb-4 pricing">
            <li>14 Day Free Trial</li>
            <li>Daily feed sync</li>
            <li>4-Platform Support</li>
            <li>Includes sale pricing</li>
            <li>Includes variants</li>
            <li>Google Category mapping</li>
            <li>Easy install</li>
            <li>Dedicated Support</li>
          </ul>
        </div>
      </div>
    </div>
  )

}

export default PricingCard;